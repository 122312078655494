import { ApplicationScope } from "@superblocksteam/shared";
import { get } from "lodash";
import moment from "moment-timezone";
import React from "react";
import { put, select } from "redux-saga/effects";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { setMetaProp } from "legacy/actions/metaActions";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import {
  ISO_DATE_FORMAT,
  VALIDATION_TYPES,
} from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { getWidgetMetaProps, getWidgets } from "legacy/selectors/sagaSelectors";
import {
  DATE_OUTPUT_FORMATS,
  TIMEZONE_OPTIONS,
} from "legacy/utils/FormatUtils";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { getParentPath } from "utils/dottedPaths";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { DropdownWidgetProps } from "../DropdownWidget/DropdownWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import { typographyProperties } from "../styleProperties";
import withMeta from "../withMeta";
import {
  DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
  DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
} from "./Constants";
import { DatePickerComponentWithLayoutManaged } from "./DatePickerComponentWithLayoutManaged";
import { formatIncludesTime } from "./utils";
import type {
  DerivedPropertiesMap,
  WidgetActionHook,
  WidgetActionResponse,
  CanvasWidgetsReduxState,
} from "../Factory";
import type { DatePickerWidgetProps } from "./types";

const DEFAULT_DATE_FORMAT = "DD/MM/YYYY HH:mm";

class DatePickerWidget extends BaseWidget<DatePickerWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "label",
            label: "Label",
            helpText: "Sets a label text for the Datepicker",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            updateHook: updateHeightOnAddOrRemove,
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            textStyleParentDottedPath: "labelProps",
            propertyNameForHumans: "Label",
            defaultVariant: DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
            hiddenIfPropertyNameIsNullOrFalse: "label",
          }),
          {
            propertyName: "defaultDate",
            label: "Default date",
            helpText:
              "Sets the default date of the component. The date is updated if the default date changes",
            controlType: "DATE_PICKER",
            placeholderText: "Enter default date",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "...", // this gets overriden in applyActionHook
          },
          {
            helpText: "Sets the format of the selected date",
            propertyName: "dateFormat",
            label: "Value format",
            controlType: "DROP_DOWN",
            isJSConvertible: true,
            options: DATE_OUTPUT_FORMATS,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Sets the format of the date displayed in the UI",
            propertyName: "displayDateFormat",
            label: "Display format",
            controlType: "DROP_DOWN",
            isJSConvertible: true,
            options: DATE_OUTPUT_FORMATS,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValueFn: ({ props }: { props: DatePickerWidgetProps }) => {
              if (props.dateFormat) {
                return props.dateFormat;
              }
              return DEFAULT_DATE_FORMAT;
            },
          },
          {
            helpText:
              "Allows you to control the timezone of the Datepicker's output value and display",
            propertyName: "manageTimezone",
            label: "Manage timezone",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Timezone of the Datepicker's output value",
            propertyName: "timezone",
            label: "Value timezone",
            controlType: "DROP_DOWN",
            isJSConvertible: false,
            options: TIMEZONE_OPTIONS,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: DropdownWidgetProps, propertyPath: string) => {
              const parentPath = getParentPath(propertyPath);
              const manageTimezone = get(
                props,
                `${
                  parentPath ? `${parentPath}.manageTimezone` : "manageTimezone"
                }`,
                "",
              );
              return !manageTimezone;
            },
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText: "Timezone of the date displayed in the UI",
            propertyName: "displayTimezone",
            label: "Display timezone",
            controlType: "DROP_DOWN",
            isJSConvertible: false,
            options: TIMEZONE_OPTIONS,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: DropdownWidgetProps, propertyPath: string) => {
              const parentPath = getParentPath(propertyPath);
              const manageTimezone = get(
                props,
                `${
                  parentPath ? `${parentPath}.manageTimezone` : "manageTimezone"
                }`,
                "",
              );
              return !manageTimezone;
            },
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "twentyFourHourTime",
            label: "24 hour time",
            controlType: "SWITCH",
            defaultValue: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: DropdownWidgetProps, propertyPath: string) => {
              const parentPath = getParentPath(propertyPath);

              const dateFormat = get(
                props,
                `${parentPath ? `${parentPath}.dateFormat` : "dateFormat"}`,
                DEFAULT_DATE_FORMAT,
              );
              return !formatIncludesTime(dateFormat);
            },
            propertyCategory: PropsPanelCategory.Content,
          },
          ...typographyProperties({
            textStyleParentDottedPath: "inputProps",
            propertyNameForHumans: "Input",
            defaultVariant: DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
          }),
          {
            propertyName: "showIcon",
            label: "Icon",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
        ],
      },
      sizeSection({
        heightSupportsFitContent: true,
      }),
      {
        sectionName: "Validation",
        sectionCategory: PropsPanelCategory.Interaction,
        children: [
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes date picker mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "minDate",
            label: "Min date",
            controlType: "DATE_PICKER",
            placeholderText: "Minimum Date",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "",
          },
          {
            propertyName: "maxDate",
            label: "Max date",
            controlType: "DATE_PICKER",
            placeholderText: "Maximum Date",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "",
          },
        ],
      },
      {
        sectionName: "Advanced",
        children: [
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables user interaction with this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: VALIDATION_TYPES.BOOLEAN,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [getPopoverConfig("onDateSelected", "")],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      defaultDate: VALIDATION_TYPES.DEFAULT_DATE,
      timezone: VALIDATION_TYPES.TEXT,
      enableTimePicker: VALIDATION_TYPES.BOOLEAN,
      dateFormat: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      datePickerType: VALIDATION_TYPES.TEXT,
      maxDate: VALIDATION_TYPES.MAX_DATE,
      minDate: VALIDATION_TYPES.MIN_DATE,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.selectedDate ? this.selectedDate : undefined }}`,
      isValid: `{{ (() => {
        if (this.isRequired && !this.selectedDate) {
          return false;
        } else if (!this.isRequired && !this.selectedDate) {
          return true;
        }
        return moment(this.selectedDate, this.dateFormat).isValid();
      })() }}`,
      outputDateLocal: `{{ (() => moment(this.selectedDate, this.dateFormat).format('YYYY-MM-DDTHH:mm:ss.sssZ'))() }}`,
      outputDateUtc: `{{ (() =>  moment(this.selectedDate, this.dateFormat).toISOString())() }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedDate: "defaultDate",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isValid: true,
      selectedDate: undefined,
      isTouched: false,
    };
  }

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<UpdateWidgetPropertiesPayload>;
  }): Generator<any, any, any> {
    const { widgetId, widgets, action } = params;
    if (
      widgets[widgetId].type !== WidgetTypes.DATE_PICKER_WIDGET ||
      widgetId !== action.payload.widgetId
    ) {
      return;
    }

    const widget = widgets[widgetId] as DatePickerWidgetProps;
    const newUpdates: WidgetActionResponse = [];
    switch (action.type) {
      case updateWidgetProperties.type: {
        if (!updateWidgetProperties.match(action)) break;
        const updates = action.payload.updates;
        if (updates.dateFormat || updates.defaultDate) {
          const widgetMeta: ReturnType<typeof getWidgetMetaProps> =
            yield select(getWidgetMetaProps, widgetId);
          const previousWidgets = yield select(getWidgets);
          const previousDateFormat =
            previousWidgets[widgetId].dateFormat || ISO_DATE_FORMAT;
          const newDateFormat = widget.dateFormat || ISO_DATE_FORMAT;
          const timezone = widget.timezone;
          const defaultDateIsDynamic = (
            widget.dynamicPropertyPathList ?? []
          ).some((binding) => binding.key === "defaultDate");
          // Update defaultDate with newFormat
          if (!defaultDateIsDynamic && widget.defaultDate) {
            const defaultDate = timezone
              ? moment.tz(
                  widget.defaultDate,
                  [previousDateFormat, DEFAULT_DATE_FORMAT],
                  timezone,
                )
              : moment(widget.defaultDate, [
                  previousDateFormat,
                  DEFAULT_DATE_FORMAT,
                ]);
            if (defaultDate.isValid()) {
              newUpdates.push({
                widgetId,
                widget: {
                  ...widget,
                  defaultDate: defaultDate.format(newDateFormat),
                },
              });
            }
          }
          // Update selectedDate with newFormat
          const selectedDate =
            widgetMeta && (widgetMeta.selectedDate as string);
          if (selectedDate) {
            const metaDate = timezone
              ? moment.tz(selectedDate, previousDateFormat, timezone)
              : moment(selectedDate, previousDateFormat);
            if (metaDate.isValid()) {
              yield put(
                setMetaProp(
                  widgetId,
                  "selectedDate",
                  metaDate.format(newDateFormat),
                ),
              );
            }
          }
          break;
        }
      }
    }
    return newUpdates;
  };

  handleDatepickerClose = () => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  getPageView() {
    return (
      <DatePickerComponentWithLayoutManaged
        {...this.props}
        onDateSelected={this.onDateSelected}
        onDatePickerClosed={this.handleDatepickerClose}
      />
    );
  }

  onDateSelected = (selectedDate: string) => {
    const { onDateSelected } = this.props;

    this.props.updateWidgetMetaProperty(
      "selectedDate",
      selectedDate,
      createRunEventHandlersPayloadOptional({
        steps: onDateSelected,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_DATE_SELECTED,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.DATE_PICKER_WIDGET;
  }
}
export default DatePickerWidget;
export const ConnectedDatePickerWidget = withMeta(DatePickerWidget);
